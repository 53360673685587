$blue3: #1890ff;

@import 'antd/dist/antd.css';
@import "normalize.css/normalize.css";
@import "@blueprintjs/icons/lib/css/blueprint-icons.css";
@import "@blueprintjs/core/src/blueprint.scss";
@import "@blueprintjs/select/src/blueprint-select.scss";
@import 'react-phone-number-input/style.css';

* {
  font-family: poppins, sans-serif;
}

html,body {
  background-color: white;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  margin-top: 0;
  margin-bottom: 0;
}

input {
  border: 1px solid #969696;
  border-radius: 8px;
  padding: 14px 18px;
  box-sizing: border-box;
  outline: none !important;
}

a {
  color: #1890FF;
}